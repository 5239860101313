<template>
  <Box title="电梯故障率" width="419px" height="220px">
    <template slot="title-right">
      <Select v-model="value" />
    </template>
    <div class="d-flex wrapper flex-column">
      <Pointer
        :data="[
          {
            label: '百分比（%）',
            color: [' rgba(255, 25, 25, 0.60) ', 'rgba(255, 25, 25, 1) '],
          },
        ]"
      />
      <Echart :options="innerOptions" height="130px" width="100%"></Echart>
    </div>
  </Box>
</template>

<script>
import Box from "../../common/box";
import Echart from "@/common/echart";
// import FilterLabel from "../../common/filter";
import Pointer from "../../common/pointer";
import Select from "../../common/select";
var gradientColor = [
  { offset: 1, color: "rgba(255, 25, 25, 0.60) " },
  { offset: 0, color: "rgba(255, 25, 25, 1)" },
];
export default {
  name: "guzhangrate",
  props: {
    size: {
      type: String,
    },
  },
  data() {
    return {
    
      isActive: true,
      value: ["电梯", "全天", "月"],
    };
  },
  computed: {
    innerOptions() {
      return {
        tooltip: {
          trigger: "axis",

          formatter: (arr) => {
            const { axisValue, data } = arr[0];
            console.log(axisValue, data);
            return `<div class="tooltip-w d-flex flex-column">
                  <div class="tooltip-x">${axisValue}</div>
                  <div class="tooltip-y"> 故障率：${data}%</div>
                  </div>`;
          },
        },
        xAxis: {
          type: "category",
          data: this.$store.state.faultRate.map((item) => {
            return item.name;
          }),
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            data: this.$store.state.faultRate.map((item) => {
              return item.faultRate;
            }),
            type: "bar",
            itemStyle: {
              color: new this.$echarts.graphic.LinearGradient(
                0,
                0,
                0,
                1,
                gradientColor
              ),
              barWidth: 7,
              barBorderRadius: [2, 2, 0, 0],
            },
          },
        ],
      };
    },
  },
  components: {
    Box,
    Echart,
    // FilterLabel,
    Pointer,
    Select,
  },
  mounted() {},
  beforeDestroy() {},
  methods: {},
  watch: {
    value: {
      handler(newValue) {
        const [dimension, timeSegmentType, dateType] = newValue;

        const map1 = {
          电梯: "lift",
          楼栋: "building",
        };
        const map2 = {
          全天:1,
          白天: 2,
          夜班: 3,
        };
        const map3 = {
          日: 1,
          月: 2,
        };
        const params = {
          dimension: map1[dimension],
          timeSegmentType:map2[timeSegmentType],
          dateType: map3[dateType],
        };
        this.$store.dispatch("fetchFaultRate", {
          projectName: this.$store.state.projectInfo.projectName,
          ...params,
        });
      },
      deep: true, // 深度监听，以检测数组内部元素的变化
    },
  },
};
</script>

<style lang="scss" scoped></style>
