<template>
  <Box title="电梯运行统计" :width="calcWidth" :height="calcHeight">
    <div :style="{ marginTop: size === 'small' ? '10px' : '38px' }">
      <el-row :gutter="18">
        <el-col v-for="item in innerData" :key="item.label" :span="calcSpan">
          <div
            :style="{ height: size === 'small' ? '88px' : '108px' }"
            class="wrapper d-flex flex-column jc-between ai-start"
          >
            <p class="label">{{ item.label }}</p>
            <p class="value">
              {{ item.value }} <span class="x">{{ item.x }}</span>
            </p>
            <img :src="item.url" alt="" />
          </div>
        </el-col>
      </el-row>
    </div>
  </Box>
</template>

<script>
import Box from "../../common/box";
export default {
  name: "overviewbar",
  props: {
    size: { type: String },
  },
  data() {
    return {
      data: [
        {
          label: "累计运行时长",
          p: "totalRunTime",
          x: "h",
          url: require("@/assets/s_1.png"),
        },
        {
          label: "累计耗电量",
          p: "",
          x: "kWh",
          url: require("@/assets/s_2.png"),
        },
        {
          label: "累计运行次数",
          p: "",
          x: "h",
          url: require("@/assets/s_3.png"),
        },
        {
          label: "累计处理事件",
          p: "removedEventTotalCount",
          x: "",
          url: require("@/assets/s_4.png"),
        },
      ],
    };
  },
  computed: {
    innerData() {
      return this.data.map((item) => {
        return {
          ...item,
          value: (this.$store.state.statistics[item.p] || 0).toLocaleString(),
        };
      });
    },
    calcWidth() {
      return this.size === "small" ? "490px" : "940px";
    },
    calcHeight() {
      return this.size === "small" ? "268px" : "220px";
    },
    calcSpan() {
      return this.size === "small" ? 12 : 6;
    },
  },
  components: {
    Box,
  },
  mounted() {},
  beforeDestroy() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
img {
}
.wrapper {
  padding: 14px 12px;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 12px;
  height: 88px;
  backdrop-filter: blur(10px);
  margin-bottom: 10px;
  position: relative;
}
.label {
  font-family: PingFangSC-Medium;
  font-size: 12px;
  color: #abb6d2;
  letter-spacing: 0;
  font-weight: 500;
}
.value {
  font-family: CenturyGothic-Bold;
  font-size: 32px;
  color: #ffffff;
  letter-spacing: 0;
  line-height: 39px;
  font-weight: 700;
}
.x {
  font-family: CenturyGothic-Bold;
  font-size: 16px;
  color: #ffffff;
  letter-spacing: 0;
  line-height: 39px;
  font-weight: 700;
}
img {
  width: 43px;
  height: 43px;
  top: 0px;
  right: 0px;
  position: absolute;
}
</style>
