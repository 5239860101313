<template>
  <div class="w d-flex jc-center ai-center" @click="changeMode">
    <img :src="imgUrl" />
  </div>
</template>

<script>
const iconUrl = {
  map: require("@/assets/lift-map.png"),
  list: require("@/assets/lift-list.png"),
};
export default {
  name: "tool",
  props: {},
  data() {
    return {
      mode: "map",
    };
  },
  computed: {
    imgUrl() {
      return iconUrl[this.mode];
    },
  },
  methods: {
    changeMode() {
      const mode = this.mode === "map" ? "list" : "map";
      this.mode = mode;
      this.$emit("changeMode", mode);
    },
  },
};
</script>

<style lang="scss" scoped>
.w {
  width: 95px;
  height: 96px;
  background: rgba(58, 65, 81, 0.3);
  border-radius: 20px;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(108, 173, 255, 0.27);
  cursor: pointer;
}
img {
  width: 60px;
  height: 60px;
}
</style>
