<template>
  <Box
    title="电梯运行时长排行"
    :width="size === 'small' ? '490px' : '514px'"
    height="220px"
  >
    <template slot="title-right">
      <!-- <FilterLabel :data="['当日', '当月']" /> -->
      <Select v-model="value" />
    </template>
    <div class="d-flex wrapper flex-column">
      <Pointer
        :data="[
          { label: '运行时长', color: ['rgba(25,98,255,0.60)', '#1962FF'] },
        ]"
      />
      <Echart :options="options" height="130px" width="100%"></Echart>
    </div>
  </Box>
</template>

<script>
import Box from "../../common/box";
import Echart from "@/common/echart";

// import FilterLabel from "../../common/filter";
import Select from "../../common/select";
import Pointer from "../../common/pointer";
var gradientColor = [
  { offset: 1, color: "rgba(25,98,255,0.60)" },
  { offset: 0, color: "#1962FF" },
];
export default {
  name: "runingtime",
  props: {
    size: {
      type: String,
    },
  },
  data() {
    return {
      options: {
        tooltip: {
          trigger: "axis",
          formatter: (arr) => {
            const { axisValue, data } = arr[0];
            console.log(axisValue, data);
            return `<div class="tooltip-w d-flex flex-column">
              <div class="tooltip-title">2024/08/01</div>
              <div class="tooltip-x">${axisValue}</div>
              <div class="tooltip-y">运行时长：${data}h</div>
              </div>`;
          },
        },
        xAxis: {
          type: "category",
          data: [
            "东一楼 A 梯子",
            "东一楼 A 梯子",
            "东一楼 A 梯子",
            "东一楼 A 梯子",
            "东一楼 A 梯子",
            "东一楼 A 梯子",
            "东一楼 A 梯子",
            "东一楼 A 梯子",
          ],
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            data: [8, 7, 6, 5, 3, 6, 8, 8],
            type: "bar",
            itemStyle: {
              color: new this.$echarts.graphic.LinearGradient(
                0,
                0,
                0,
                1,
                gradientColor
              ),

              barBorderRadius: [3, 3, 0, 0],
            },
          },
        ],
      },
      value: ["电梯", "全天", "月"],
    };
  },
  computed: {
    calcWidth() {
      return this.size === "small" ? "500px" : "514px";
    },
  },
  components: {
    Box,
    Echart,
    // FilterLabel,
    Select,
    Pointer,
  },
  mounted() {},
  beforeDestroy() {},
  methods: {},
};
</script>

<style lang="scss" scoped></style>
