<template>
  <div class="d-flex jc-center ai-center">
    <el-select
      @change="changeValue1"
      :style="{ marginRight: '10px' }"
      :value="value[0]"
      placeholder=""
    >
      <el-option
        v-for="item in options1"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      >
      </el-option>
    </el-select>
    <el-select
      @change="changeValue2"
      :style="{ marginRight: '10px' }"
      :value="value[1]"
      placeholder=""
    >
      <el-option
        v-for="item in options2"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      >
      </el-option>
    </el-select>
    <el-select @change="changeValue3" :value="value[2]" placeholder="">
      <el-option
        v-for="item in options3"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      >
      </el-option>
    </el-select>
  </div>
</template>

<script>
export default {
  name: "select",
  data() {
    return {
      options1: [
        {
          value: "电梯",
          label: "电梯",
        },
        {
          value: "楼栋",
          label: "楼栋",
        },
      ],
      options2: [
        {
          value: "全天",
          label: "全天",
        },
        {
          value: "白天",
          label: "白天",
        },
        {
          value: "夜班",
          label: "夜班",
        },
      ],
      options3: [
        {
          value: "日",
          label: "日",
        },
        {
          value: "月",
          label: "月",
        },
      ],
    };
  },
  props: {
    data: {
      type: Array,
    },
    value: {
      type: Array,
    },
  },
  computed: {},
  methods: {
    changeValue1(value) {
      const arr = [value, this.value[1], this.value[2]];
      this.$emit("input", arr);
    },
    changeValue2(value) {
      const arr = [this.value[0], value, this.value[2]];
      this.$emit("input", arr);
    },
    changeValue3(value) {
      const arr = [this.value[0], this.value[1], value];
      this.$emit("input", arr);
    },
  },
};
</script>

<style lang="scss" scoped>
.p-color {
  //   background-image: linear-gradient(
  //     0deg,
  //     #1962ff 0%,
  //     rgba(77, 161, 255, 0.68) 100%
  //   );
  border-radius: 1px;
  width: 10px;
  height: 10px;
  margin-right: 10px;
}
.y-label {
  font-family: PingFangSC-Regular;
  font-size: 10px;
  color: #8e9ab4;
  letter-spacing: 0;
  font-weight: 400;
}
</style>
