<template>
  <div class="w d-flex jc-between ai-center">
    <div class="d-flex jc-center ai-center">
      <img
        :style="{
          width: '218px',
          height: '30px',
        }"
        src="../../assets/logo.png"
      />
      <div class="line"></div>
      <img class="sublogo" src="../../assets/sublogo.png" alt="" />
      <div class="title">{{ $store.state.projectInfo.projectName }}电梯运维中心</div>
    </div>
    <div class="d-flex jc-center ai-center">
      <img
        :style="{
          width: '38px',
          height: '33px',
          marginRight: '10px',
        }"
        :src="weatherIcon"
      />
      <div
        :style="{ marginRight: '20px' }"
        class="right-text d-flex jc-center ai-start flex-column"
      >
        <div>{{ $store.state.weather.temp }}°C</div>
        <div>{{ $store.state.projectInfo.countyName }}</div>
      </div>
      <div class="right-text d-flex jc-center ai-start flex-column">
        <div>{{ formatTime.date }}</div>
        <div class="d-flex jc-center ai-center">
          <div :style="{ marginRight: '10px' }">{{ formatTime.time }}</div>
          <div>{{ formatTime.week }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export const WeatherIconMap = {
  100: "sunny",
  102: "sunny",
  150: "sunny",
  152: "sunny",
  101: "cloudy",
  103: "cloudy",
  151: "cloudy",
  153: "cloudy",
  500: "cloudy",
  104: "overcastCloudy",
  154: "overcastCloudy",
  501: "overcastCloudy",
  502: "overcastCloudy",
  503: "overcastCloudy",
  504: "overcastCloudy",
  507: "overcastCloudy",
  508: "overcastCloudy",
  509: "overcastCloudy",
  510: "overcastCloudy",
  511: "overcastCloudy",
  512: "overcastCloudy",
  513: "overcastCloudy",
  514: "overcastCloudy",
  515: "overcastCloudy",
  300: "raining",
  301: "raining",
  302: "raining",
  303: "raining",
  304: "raining",
  305: "raining",
  306: "raining",
  307: "raining",
  308: "raining",
  309: "raining",
  310: "raining",
  311: "raining",
  312: "raining",
  313: "raining",
  314: "raining",
  315: "raining",
  316: "raining",
  317: "raining",
  318: "raining",
  350: "raining",
  351: "raining",
  399: "raining",
  400: "raining",
  401: "raining",
  402: "raining",
  403: "raining",
  404: "raining",
  405: "raining",
  406: "raining",
  407: "raining",
  408: "raining",
  409: "raining",
  410: "raining",
  456: "raining",
  457: "raining",
  499: "raining",
  999: "未知",
};

import Moment from "moment";
export default {
  name: "header",
  data() {
    return {
      timer: null,
      currentTime: new Date(),
    };
  },
  computed: {
    formatTime() {
      const map = {
        0: "日",
        1: "一",
        2: "二",
        3: "三",
        4: "四",
        5: "五",
        6: "六",
      };
      return {
        date: Moment(this.currentTime).format("YYYY/MM/DD"),
        time: Moment(this.currentTime).format("HH:mm"),
        week: "星期" + map[Moment(this.currentTime).day()],
      };
    },
    weatherIcon() {
      const key = this.$store.state.weather.icon;
      return `https://static-btri.midea.com/lowcode/weather/${WeatherIconMap[key]}.png`;
    },
  },
  components: {},
  mounted() {},
  created() {
    setInterval(() => {
      this.currentTime = new Date();
    }, 1000);
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.w {
  height: 80px;
  padding: 20px 30px;
}
.logo {
  font-size: 35px;
  color: #fa4616;
  font-weight: 900;
  letter-spacing: 3px;
}
.line {
  border: 1px solid #979797;
  height: 27px;
  margin-left: 26px;
  margin-right: 26px;
}
.title {
  font-family: PingFangSC-Regular;
  font-size: 26px;
  color: #d4dae8;
  letter-spacing: 0;
  font-weight: 400;
}
.sublogo {
  width: 28px;
  height: 28px;
  margin-right: 10px;
}
.right-text {
  font-family: PingFangSC-Semibold;
  font-size: 14px;
  color: #ffffff;
  letter-spacing: 0;
  line-height: 20px;
  font-weight: 600;
}
</style>
