var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Box',{attrs:{"title":"电梯总览","width":_vm.width,"height":_vm.height}},[_c('template',{slot:"title-right"},[_c('div',{staticClass:"title-right"},[_vm._v(" 电梯总数："+_vm._s(_vm.data.totalCount)+" ")])]),_c('div',{staticClass:"d-flex jc-between ai-center",style:({
      marginTop: '20px',
    })},[_c('div',{style:({
        width: '30%',
      })},[_c('div',{staticClass:"cir"},[_c('div',{staticClass:"num"},[_vm._v(_vm._s(_vm.data.onlinePercentage)+"%")]),_c('div',{staticClass:"t"},[_vm._v("在线率")]),_c('div',{staticClass:"clip",class:{
            auto: _vm.percent > 50,
          }},[_c('div',{staticClass:"left",style:({ transform: _vm.rotate })}),_c('div',{staticClass:"right",class:{
              'width-none': _vm.percent <= 50,
            }})]),_c('div',{staticClass:"cir-2",style:({ top: _vm.top, left: _vm.left })},[_c('div',{staticClass:"cir-3"})])])]),_c('div',{staticClass:"d-flex wrapper"},[_c('div',{staticClass:"d-flex flex-column jc-center al-center"},[_c('div',{staticClass:"number"},[_vm._v(_vm._s(_vm.data.normalCount))]),_c('div',{staticClass:"status"},[_vm._v("正常")]),_c('img',{attrs:{"src":require("../../assets/success.png")}})]),_c('div',{staticClass:"d-flex flex-column jc-center al-center"},[_c('div',{staticClass:"number"},[_vm._v(_vm._s(_vm.data.maintenanceCount))]),_c('div',{staticClass:"status"},[_vm._v("检修")]),_c('img',{attrs:{"src":require("../../assets/weixiu.png")}})]),_c('div',{staticClass:"d-flex flex-column jc-center al-center"},[_c('div',{staticClass:"number"},[_vm._v(_vm._s(_vm.data.faultCount))]),_c('div',{staticClass:"status"},[_vm._v("故障")]),_c('img',{attrs:{"src":require("../../assets/guzhang.png")}})]),_c('div',{staticClass:"d-flex flex-column jc-center al-center"},[_c('div',{staticClass:"number"},[_vm._v(_vm._s(_vm.data.offlineCount))]),_c('div',{staticClass:"status"},[_vm._v("离线")]),_c('img',{attrs:{"src":require("../../assets/xiaxian.png")}})])])])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }