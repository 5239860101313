<template>
  <Box
    title="事件趋势图"
    :width="size === 'small' ? '622px' : '940px'"
    height="240px"
  >
    <div class="d-flex wrapper flex-column">
      <Pointer
        :data="[
          { label: '预警', color: ['rgba(25,98,255,0.60)', '#1962FF'] },
          { label: '故障', color: ['rgba(255,25,25,0.60)', '#FF1919'] },
        ]"
      />
      <Echart :options="options" height="150px" width="100%"></Echart>
    </div>
  </Box>
</template>

<script>
import Box from "../../common/box";
import Echart from "@/common/echart";
import Pointer from "../../common/pointer";
var gradientColor1 = [
  { offset: 1, color: "rgba(25,98,255,0.60)" },
  { offset: 0, color: "#1962FF" },
];
var gradientColor2 = [
  { offset: 1, color: "rgba(255,25,25,0.60)" },
  { offset: 0, color: "#FF1919" },
];
export default {
  name: "eventrend",
  props: {
    size: {
      type: String,
    },
  },
  data() {
    return {
      options: {
        tooltip: {
          trigger: "axis",

          formatter: (arr) => {
            const { axisValue, data } = arr[1];
            return `<div class="tooltip-w d-flex flex-column">
                <div class="tooltip-x">${axisValue}</div>
                <div class="tooltip-y">故障：${data}</div>
                </div>`;
          },
        },
        xAxis: {
          type: "category",
          data: [
            "1月",
            "2月",
            "3月",
            "4月",
            "5月",
            "6月",
            "7月",
            "8月",
            "9月",
            "10月",
            "11月",
            "12月",
          ],
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            barWidth: 7,
            data: Array.from({ length: 13 }, () => 0),
            type: "bar",
            itemStyle: {
              color: new this.$echarts.graphic.LinearGradient(
                0,
                0,
                0,
                1,
                gradientColor1
              ),
              barBorderRadius: [2, 2, 0, 0],
            },
          },
          {
            barWidth: 8,

            data: this.$store.state.eventLogYearPerMonth,
            type: "bar",
            itemStyle: {
              color: new this.$echarts.graphic.LinearGradient(
                0,
                0,
                0,
                1,
                gradientColor2
              ),
              borderColor: "rgba(255,255,255,0.3)",
              borderWidth: 1,
              borderRadius: [10, 10, 0, 0],
              shadowBlur: 2, // 模糊半径，较小的值模拟 1px 的模糊
              shadowColor: "rgba(255, 255, 255, 0.3)", // 阴影颜色
              shadowOffsetX: 0, // X 轴偏移量为 0 实现内阴影
              shadowOffsetY: 0, // Y 轴偏移量为 0 实现内阴影
            },
          },
        ],
      },
      isActive: true,
    };
  },
  computed: {},
  components: {
    Box,
    Echart,
    Pointer,
  },
  mounted() {},
  beforeDestroy() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.wrapper {
  margin-top: 8px;
}
.p-color {
  background-image: linear-gradient(
    0deg,
    #1962ff 0%,
    rgba(77, 161, 255, 0.68) 100%
  );
  border-radius: 1px;
  width: 10px;
  height: 10px;
  margin-right: 10px;
}
.s-color {
  background-image: linear-gradient(
    0deg,
    rgba(255, 25, 25, 0.6) 0%,
    #ff1919 100%
  );
  box-shadow: inset 0 1px 2px 0 rgba(255, 255, 255, 0.3);
  border-radius: 1px;
  width: 10px;
  height: 10px;
  margin-right: 10px;
}
.y-label {
  font-family: PingFangSC-Regular;
  font-size: 10px;
  color: #8e9ab4;
  letter-spacing: 0;
  font-weight: 400;
  margin-right: 44px;
}
.filter {
  background: #060913;
  border-radius: 4px;
  padding: 3px;
}
.filter-text {
  padding: 5px;
  font-family: PingFangSC-Regular;
  font-size: 12px;
  color: #9ba2b3;
  letter-spacing: 0;
  line-height: 18px;
  font-weight: 400;
  cursor: pointer;
}
.active {
  color: #ffffff;
  background: rgba(58, 65, 81, 0.3);
  border-radius: 4px;
}
</style>
