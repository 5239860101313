var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w d-flex jc-between ai-center"},[_c('div',{staticClass:"d-flex jc-center ai-center"},[_c('img',{style:({
        width: '218px',
        height: '30px',
      }),attrs:{"src":require("../../assets/logo.png")}}),_c('div',{staticClass:"line"}),_c('img',{staticClass:"sublogo",attrs:{"src":require("../../assets/sublogo.png"),"alt":""}}),_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$store.state.projectInfo.projectName)+"电梯运维中心")])]),_c('div',{staticClass:"d-flex jc-center ai-center"},[_c('img',{style:({
        width: '38px',
        height: '33px',
        marginRight: '10px',
      }),attrs:{"src":_vm.weatherIcon}}),_c('div',{staticClass:"right-text d-flex jc-center ai-start flex-column",style:({ marginRight: '20px' })},[_c('div',[_vm._v(_vm._s(_vm.$store.state.weather.temp)+"°C")]),_c('div',[_vm._v(_vm._s(_vm.$store.state.projectInfo.countyName))])]),_c('div',{staticClass:"right-text d-flex jc-center ai-start flex-column"},[_c('div',[_vm._v(_vm._s(_vm.formatTime.date))]),_c('div',{staticClass:"d-flex jc-center ai-center"},[_c('div',{style:({ marginRight: '10px' })},[_vm._v(_vm._s(_vm.formatTime.time))]),_c('div',[_vm._v(_vm._s(_vm.formatTime.week))])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }