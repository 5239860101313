<template>
  <div class="d-flex jc-start ai-center" :style="{ marginBottom: '8px' }">
    <div
      v-for="(item, index) in innerData"
      :key="index"
      class="d-flex jc-center ai-center"
      :style="{marginRight: '30px'}"
    >
      <div
        class="p-color"
        :style="{
          backgroundImage: item.bgStyle,
        }"
      ></div>
      <span class="y-label">{{ item.label }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "pointer",
  data() {
    return {};
  },
  props: {
    data: {
      type: Array,
    },
  },
  computed: {
    innerData() {
      return this.data.map((i) => ({
        ...i,
        bgStyle: `linear-gradient(
    0deg,
    ${i.color[0]} 0%,
    ${i.color[1]} 100%
  )`,
      }));
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.p-color {
  //   background-image: linear-gradient(
  //     0deg,
  //     #1962ff 0%,
  //     rgba(77, 161, 255, 0.68) 100%
  //   );
  border-radius: 1px;
  width: 10px;
  height: 10px;
  margin-right: 10px;
}
.y-label {
  font-family: PingFangSC-Regular;
  font-size: 10px;
  color: #8e9ab4;
  letter-spacing: 0;
  font-weight: 400;
}
</style>
