<template>
  <Box title="电梯总览" :width="width" :height="height">
    <template slot="title-right">
      <div class="title-right">
        电梯总数：{{ data.totalCount }}
      </div>
    </template>
    <div
      :style="{
        marginTop: '20px',
      }"
      class="d-flex jc-between ai-center"
    >
      <div
        :style="{
          width: '30%',
        }"
      >
        <div class="cir">
          <div class="num">{{ data.onlinePercentage }}%</div>
          <div class="t">在线率</div>
          <div
            class="clip"
            :class="{
              auto: percent > 50,
            }"
          >
            <div class="left" :style="{ transform: rotate }"></div>
            <div
              :class="{
                'width-none': percent <= 50,
              }"
              class="right"
            ></div>
          </div>
          <div :style="{ top, left }" class="cir-2">
            <div class="cir-3"></div>
          </div>
        </div>
      </div>
      <div class="d-flex wrapper">
        <div class="d-flex flex-column jc-center al-center">
          <div class="number">{{ data.normalCount }}</div>
          <div class="status">正常</div>
          <img src="../../assets/success.png" />
        </div>
        <div class="d-flex flex-column jc-center al-center">
          <div class="number">{{ data.maintenanceCount }}</div>
          <div class="status">检修</div>
          <img src="../../assets/weixiu.png" />
        </div>
        <div class="d-flex flex-column jc-center al-center">
          <div class="number">{{ data.faultCount }}</div>
          <div class="status">故障</div>
          <img src="../../assets/guzhang.png" />
        </div>
        <div class="d-flex flex-column jc-center al-center">
          <div class="number">{{ data.offlineCount }}</div>
          <div class="status">离线</div>
          <img src="../../assets/xiaxian.png" />
        </div>
      </div>
    </div>
  </Box>
</template>

<script>
import Box from "../../common/box";
export default {
  name: "overview",
  props: {
    width: {
      type: String,
    },
    height: {
      type: String,
    },
  },
  data() {
    return {
      percent: 80,
      left: "0px",
      top: "0px",
    };
  },
  computed: {
    rotate() {
      return `rotate(${3.6 * this.percent}deg)`;
    },
    data() {
      return this.$store.state.statusStatistics;
    },
  },
  components: {
    Box,
  },
  mounted() {
    // 圆心坐标
    const radius = 60;
    const centerX = radius - 7;
    const centerY = radius - 7;

    const angle = (this.percent / 100) * 2 * Math.PI - Math.PI / 2;

    const x = centerX + (radius - 5) * Math.cos(angle);
    const y = centerY + (radius - 5) * Math.sin(angle);
    this.left = x + "px";
    this.top = y + "px";
  },
  beforeDestroy() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.cir {
  width: 120px;
  height: 120px;
  border-radius: 50%;

  position: relative;
  background-color: transparent;
}
.clip {
  width: 120px;
  height: 120px;
  position: absolute;
  border: 10px solid transparent;

  // -webkit-backdrop-filter: blur(6px);
  // backdrop-filter: blur(6px);
  border-radius: 50%;
  clip: rect(0, 120px, 120px, 60px);
}
.left {
  width: 120px;
  height: 120px;
  position: absolute;
  border: 10px solid #1962ff;
  border-radius: 50%;
  clip: rect(0 60px 120px 0);
  top: -10px;
  left: -10px;
}
.right {
  width: 120px;
  height: 120px;
  position: absolute;
  border: 10px solid #1962ff;
  border-radius: 50%;
  clip: rect(0 120px 120px 60px);
  top: -10px;
  left: -10px;
}
.width-none {
  width: 0;
}
.auto {
  clip: auto;
}
.num {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -70%);
  font-family: CenturyGothic-Bold;
  font-size: 25.2px;
  color: #ffffff;
  letter-spacing: 0;
  font-weight: 700;
}
.t {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  font-family: PingFangSC-Medium;
  font-size: 12px;
  color: #a0a9c0;
  letter-spacing: 0;
  font-weight: 500;
}
.cir-2 {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.3);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cir-3 {
  position: absolute;
  width: 7px;
  height: 7px;
  background-color: white;
  border-radius: 50%;
}
.title-right {
  font-family: PingFangSC-Regular;
  font-size: 16px;
  color: #ffffff;
  letter-spacing: 0;
  text-align: right;
  font-weight: 400;
}
.number {
  font-family: CenturyGothic-Bold;
  font-size: 44px;
  color: #ffffff;
  letter-spacing: 0;
  //   text-align: right;
  font-weight: 700;
  margin-bottom: 6px;
  text-align: center;
}
.status {
  font-family: PingFangSC-Medium;
  font-size: 16px;
  color: #a0a9c0;
  letter-spacing: 0;
  font-weight: 500;
  text-align: center;
}
.wrapper {
  // margin-top: 20px;
  flex: 1;
}
.wrapper > div {
  flex: 1;
}
img {
  width: 15px;
  height: 15px;
  position: relative;
  top: -18px;
}
</style>
