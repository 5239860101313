<template>
  <div class="filter d-flex jc-start ai-center">
    <div
      v-for="(item, index) in data"
      :key="item"
      class="filter-text"
      v-bind:class="{ active: value === index }"
      @click="changeIndex(index)"
    >
      {{ item }}
    </div>
  </div>
</template>

<script>
export default {
  name: "filterlabel",
  data() {
    return {
      activeIndex: 0,
    };
  },
  props: {
    data: {
      type: Array,
    },
    value: {
      type: String,
    },
  },
  methods: {
    changeIndex(index) {
      // this.activeIndex = index;
      this.$emit("input", index);
    },
  },
};
</script>

<style lang="scss" scoped>
.filter {
  background: #060913;
  border-radius: 4px;
  padding: 2px;
  height: 26px;
}
.filter-text {
  flex: 1;
  padding: 3px;
  font-family: PingFangSC-Regular;
  font-size: 12px;
  color: #9ba2b3;
  letter-spacing: 0;
  line-height: 18px;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.active {
  color: #ffffff;
  background: rgba(58, 65, 81, 0.3);
  border-radius: 4px;
}
.filter-2 {
  background-color: #060913;
  opacity: 0.37;
  backdrop-filter: blur(6px);
  .active {
    background: rgba(51, 67, 103, 0.64);
  }
}
</style>
