<template>
  <Box title="近 30 天故障类型 top 5" width="234px" height="318px">
    <Capsule :data="data" />
  </Box>
</template>

<script>
import Capsule from "../../common/capsule";
import Box from "../../common/box";
const formatter = (item) => item.value + "%";
export default {
  name: "top",
  data() {
    return {
      // data: [
      //   {
      //     label: "网关串口通讯异常",
      //     value: 57.2,
      //     formatter,
      //   },
      //   { label: "外召通讯故障", value: 16.8, formatter },
      //   { label: "关门故障", value: 9.2, formatter },
      //   { label: "安全回路断开", value: 2.3, formatter },
      //   { label: "关门故障", value: 1.8, formatter },
      // ],
    };
  },
  computed: {
    data() {
      return this.$store.state.recentTop.map((item) => {
        return {
          label: item.faultContent || item.faultCode,
          value: Number(item.percentage),
          formatter,
        };
      });
    },
  },

  components: {
    Capsule,
    Box,
  },
};
</script>

<style lang="scss" scoped></style>
