<template>
  <div
    v-if="value"
    class="w d-flex flex-column"
    :class="{
      large: size === 'large',
    }"
  >
    <i class="el-icon-close icon" @click="close"></i>
    <div class="d-flex jc-start ai-center">
      <img class="title-icon" src="../../assets/alarm.png" />
      <div class="title">电梯故障警告</div>
    </div>
    <div class="w2">
      <el-row :gutter="26">
        <el-col
          :span="12"
          :style="{ marginBottom: size === 'large' ? '24px' : '12px' }"
          ><div class="d-flex jc-center ai-center">
            <div class="label">设备名称</div>
            <div class="value">{{ title }}</div>
          </div></el-col
        >
        <el-col
          :span="12"
          :style="{ marginBottom: size === 'large' ? '24px' : '12px' }"
        >
          <div class="d-flex jc-center ai-center">
            <div class="label">设备编号</div>
            <div class="value">{{ code }}</div>
          </div>
        </el-col>
        <el-col
          :span="12"
          :style="{ marginBottom: size === 'large' ? '24px' : '12px' }"
          ><div class="d-flex jc-center ai-center">
            <div class="label">事件名称</div>
            <div class="value">{{ event }}</div>
          </div></el-col
        >
        <el-col
          :span="12"
          :style="{ marginBottom: size === 'large' ? '24px' : '12px' }"
        >
          <div class="d-flex jc-center ai-center">
            <div class="label">发生时间</div>
            <div class="value">{{ innerTime }}</div>
          </div>
        </el-col>
      </el-row>
      <div class="lift-list d-flex jc-center ai-center">
        <img
          class="lift-img"
          src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ5oFffT5R7KS5Py7iRJaKwz8Y7dXOf3nsVZg&s"
        />
        <img
          class="lift-img"
          src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ5oFffT5R7KS5Py7iRJaKwz8Y7dXOf3nsVZg&s"
        />
      </div>
      <div></div>
    </div>
    <div class="d-flex jc-center ai-center" :style="{ marginTop: '12px' }">
      <div class="btn-left d-flex jc-center ai-center" @click="close">
        派发工单
      </div>
      <div class="btn-right d-flex jc-center ai-center" @click="close">
        关闭警告({{ countdown }}s)
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
const DEFAULT_COUNT = 60;
export default {
  name: "modal",
  data() {
    return {
      //   show: false,
      countdown: DEFAULT_COUNT,
      intervalId: null,
    };
  },
  props: {
    value: {
      type: Boolean,
    },
    title: {
      type: String,
    },
    code: {
      type: String,
    },
    event: {
      type: String,
    },
    time: {
      type: Number,
    },
    size: {
      type: String,
    },
  },
  computed: {
    innerTime() {
      return moment(this.time).format("YYYY/MM/DD HH:mm:ss");
    },
  },
  methods: {
    close() {
      // this.activeIndex = index;
      this.$emit("input", false);
    },
    startCountdown() {
      this.intervalId = setInterval(() => {
        if (this.countdown > 0) {
          this.countdown--;
        } else {
          clearInterval(this.intervalId);
        }
      }, 1000);
    },
  },
  mounted() {
    // this.startCountdown();
  },
  beforeDestroy() {
    clearInterval(this.intervalId);
  },
  watch: {
    countdown: {
      handler(newValue) {
        if (newValue === 0) {
          clearInterval(this.intervalId);
          this.close();
        }
      },
    },
    value: {
      handler(newValue) {
        if (newValue === false) {
          clearInterval(this.intervalId);
        } else {
          this.countdown = DEFAULT_COUNT;
          this.startCountdown();
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.w {
  padding: 10px 16px 16px 16px;
  z-index: 9999;
  width: 585px;
  height: 419px;
  background: rgba(53, 69, 107, 0.58);
  border-radius: 10px;
  border: 1px solid rgba(189, 208, 255, 1);
  //   border-image: linear-gradient(
  //       180deg,
  //       rgba(189, 208, 255, 1),
  //       rgba(128, 162, 255, 0)
  //     )
  //     1 1;
  backdrop-filter: blur(9px);
}
.large {
  width: 1170px;
  height: 837px;
  border-radius: 20px;
  padding: 21px 32px 32px 32px;
}
.icon {
  position: absolute;
  right: 14px;
  top: 14px;
  width: 12px;
  height: 12px;
  color: white;
  font-weight: 700;
  cursor: pointer;
}

.large .icon {
  width: 24px;
  height: 24px;
  top: 30px;
  right: 30px;
  font-size: 24px;
}
.title-icon {
  width: 62px;
  height: 62px;
  margin-right: 8px;
}
.large .title-icon {
  width: 123px;
  height: 123px;
  margin-right: 16px;
}
.title {
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 16px;
  color: #ffffff;
  line-height: 22px;
  text-align: center;
  font-style: normal;
}
.large .title {
  font-size: 32px;
  line-height: 45px;
}
.w2 {
  padding: 11px;
  width: 100%;
}
.large .w2 {
  padding: 20px;
}
.label {
  height: 25px;
  background: #0091ff;
  border-radius: 6px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 12px;
  color: #fbfbfb;
  line-height: 17px;
  font-style: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 82px;
  margin-right: 4px;
}
.large .label {
  height: 50px;
  width: 163px;
  font-size: 24px;
  line-height: 33px;
  margin-right: 10px;
  border-radius: 12px;
}
.value {
  height: 25px;
  width: 166px;
  background: rgba(0, 144, 255, 0.4);
  border-radius: 6px;

  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 12px;
  color: #fbfbfb;
  line-height: 17px;
  font-style: normal;
  padding: 5px 10px 3px 10px;
}
.large .value {
  width: 331px;
  height: 50px;
  font-size: 24px;
  line-height: 33px;
  border-radius: 12px;
}

.lift-list img {
  flex: 1;
  height: 183px;
  margin-right: 17px;
  padding: 10px;
}
.large .lift-list img {
  height: 367px;
  margin-right: 36px;
}
.lift-list img:last-child {
  margin-right: 0px;
}

.btn-left {
  width: 99px;
  height: 30px;
  background: #1962ff;
  border-radius: 4px;
  margin-right: 16px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 600;
  font-size: 13px;
  color: #ffffff;
  line-height: 22px;
  text-align: center;
  cursor: pointer;
}
.large .btn-left {
  width: 199px;
  height: 60px;
  font-size: 26px;
  border-radius: 9px;

  line-height: 43px;
  margin-right: 32px;
}
.btn-right {
  width: 99px;
  height: 30px;
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0.12);
  font-family: PingFangSC, PingFang SC;
  font-weight: 600;
  font-size: 13px;
  color: #ffffff;
  line-height: 22px;
  text-align: center;
  font-style: normal;
  cursor: pointer;
}
.large .btn-right {
  width: 199px;
  height: 60px;
  border-radius: 9px;
  border: 2px solid rgba(255, 255, 255, 0.12);
  font-size: 26px;

  line-height: 43px;
}
</style>
