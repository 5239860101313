var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box-wrapper d-flex flex-column",class:{
    map: _vm.mode === 'map' && _vm.size === 'small',
    list: _vm.mode === 'list' && _vm.size === 'small',
    large: _vm.size === 'large',
    large_right: _vm.size === 'large' && _vm.showRight,
  }},[_c('div',{staticClass:"d-flex jc-center ai-center"},[_c('div',{style:({ flex: 1 })},[_c('div',{staticClass:"d-flex jc-between ai-center"},[_c('div',{staticClass:"d-flex jc-start ai-center"},[_c('img',{staticClass:"iconUrl",attrs:{"src":[_vm.iconUrl]}}),_c('div',{staticClass:"iconlabel"},[_vm._v(_vm._s(_vm.statusMap[_vm.status].label))])]),_c('div',{staticClass:"time"},[_vm._v(_vm._s(_vm.time))])]),_c('img',{staticClass:"lift-url",attrs:{"src":_vm.statusMap[_vm.status].url}}),_c('div',{staticClass:"bottom d-flex jc-between ai-center"},[_c('div',{staticClass:"bg",style:({ backgroundColor: _vm.statusMap[_vm.status].color })}),(_vm.overflow)?_c('el-popover',{attrs:{"trigger":"hover","placement":"top"}},[_c('div',{staticClass:"d-flex flex-column jc-center ai-start"},[_c('div',{staticClass:"label-p",style:({
                fontSize: _vm.size === 'large' ? '22px' : '16px',
                marginBottom: _vm.size === 'large' ? '6px' : '3px',
              })},[_vm._v(" "+_vm._s(_vm.label)+" ")]),_c('div',{staticClass:"id-p",style:({
                fontSize: _vm.size === 'large' ? '16px' : '13px',
              })},[_vm._v(" "+_vm._s(_vm.id)+" ")])]),_c('div',{staticClass:"d-flex flex-column bottom-left",attrs:{"slot":"reference"},slot:"reference"},[_c('div',{ref:"label",staticClass:"label mb-2 label-1"},[_vm._v(_vm._s(_vm.label))]),_c('div',{ref:"id",staticClass:"id"},[_vm._v(_vm._s(_vm.id))])])]):_vm._e(),(!_vm.overflow)?_c('div',{staticClass:"d-flex flex-column bottom-left"},[_c('div',{ref:"label",staticClass:"label mb-2 label-1"},[_vm._v(_vm._s(_vm.label))]),_c('div',{ref:"id",staticClass:"id"},[_vm._v(_vm._s(_vm.id))])]):_vm._e(),_c('div',{staticClass:"d-flex flex-column bottom-right",class:{
            'jc-between': true,
            bignumber: _vm.floor > 9 || _vm.floor < 0,
            smallnumber: _vm.floor < 10 && _vm.floor >= 0,
          }},[_c('div',[(_vm.dir !== '0')?_c('img',{style:({
                width: _vm.size === 'small' ? '18px' : '30px',
                height: _vm.size === 'small' ? '12px' : '20px',
                position: 'relative',
                right: _vm.size === 'small' ? '2px' : '7px',
                top: _vm.size === 'small' ? '3px' : '5px',
                transform: _vm.dir === '1' ? 'rotate(0deg)' : 'rotate(180deg)',
              }),attrs:{"src":require("../../assets/arrow.png")}}):_vm._e(),_c('div')]),_c('div',{staticClass:"d-flex jc-between ai-center",style:({
              position: 'relative',
              left: _vm.size === 'small' ? '-2px' : '-4px',
            })},[_c('div',{staticClass:"br-text-num"},[_vm._v(" "+_vm._s(_vm.floor)+" ")]),_c('div',{staticClass:"br-text-x"},[_vm._v(" "+_vm._s(_vm.people)+" ")])])])],1)]),((_vm.mode === 'map' && _vm.size === 'small') || _vm.showRight)?_c('div',{staticClass:"right",style:({ flex: 1 })},[_c('LiftItem',{attrs:{"size":_vm.size,"mode":_vm.mode}})],1):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }