<template>
  <div
    class="box-wrapper d-flex flex-column"
    :class="{
      map: mode === 'map' && size === 'small',
      list: mode === 'list' && size === 'small',
      large: size === 'large',
      large_right: size === 'large' && showRight,
    }"
  >
    <div class="d-flex jc-center ai-center">
      <div :style="{ flex: 1 }">
        <div class="d-flex jc-between ai-center">
          <div class="d-flex jc-start ai-center">
            <img class="iconUrl" :src="[iconUrl]" />
            <div class="iconlabel">{{ statusMap[status].label }}</div>
          </div>
          <div class="time">{{ time }}</div>
        </div>
        <img class="lift-url" :src="statusMap[status].url" />
        <div class="bottom d-flex jc-between ai-center">
          <div
            :style="{ backgroundColor: statusMap[status].color }"
            class="bg"
          ></div>
          <el-popover v-if="overflow" trigger="hover" placement="top">
            <div  class="d-flex flex-column jc-center ai-start">
              <div
                class="label-p"
                :style="{
                  fontSize: size === 'large' ? '22px' : '16px',
                  marginBottom: size === 'large' ? '6px' : '3px',
                }"
              >
                {{ label }}
              </div>
              <div
                :style="{
                  fontSize: size === 'large' ? '16px' : '13px',
                }"
                class="id-p"
              >
                {{ id }}
              </div>
            </div>
            <div slot="reference" class="d-flex flex-column bottom-left">
              <div class="label mb-2 label-1" ref="label">{{ label }}</div>
              <div class="id" ref="id">{{ id }}</div>
            </div>
          </el-popover>
          <div v-if="!overflow" class="d-flex flex-column bottom-left">
              <div class="label mb-2 label-1" ref="label">{{ label }}</div>
              <div class="id" ref="id">{{ id }}</div>
            </div> 
          <div
            class="d-flex flex-column bottom-right"
            :class="{
              'jc-between': true,
              bignumber: floor > 9 || floor < 0,
              smallnumber: floor < 10 && floor >= 0,
            }"
          >
            <div>
              <img
                v-if="dir !== '0'"
                :style="{
                  width: size === 'small' ? '18px' : '30px',
                  height: size === 'small' ? '12px' : '20px',
                  position: 'relative',
                  right: size === 'small' ? '2px' : '7px',
                  top: size === 'small' ? '3px' : '5px',
                  transform: dir === '1' ? 'rotate(0deg)' : 'rotate(180deg)',
                }"
                src="../../assets/arrow.png"
              />
              <div></div>
            </div>
            <div
              class="d-flex jc-between ai-center"
              :style="{
                position: 'relative',
                left: size === 'small' ? '-2px' : '-4px',
              }"
            >
              <div class="br-text-num">
                {{ floor }}
              </div>
              <div class="br-text-x">
                {{ people }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="right"
        :style="{ flex: 1 }"
        v-if="(mode === 'map' && size === 'small') || showRight"
      >
        <LiftItem :size="size" :mode="mode" />
      </div>
    </div>
  </div>
</template>

<script>
import LiftItem from "../liftitem";
export default {
  name: "lift",

  props: {
    rowData: {
      type: Object,
    },
    showRight: {
      type: Boolean,
    },
    size: {
      type: String,
    },
    mode: {
      type: String,
    },
    status: {
      type: String,
      default: "success",
    },
    time: {
      type: String,
    },
    label: {
      type: String,
    },
    id: {
      type: String,
    },
    direction: {
      type: String,
    },
    headcount: {
      type: Number,
    },
    top: {
      type: String,
    },
    left: {
      type: String,
    },
  },
  data() {
    return {
      statusMap: {
        success: {
          iconUrl: require("@/assets/success.png"),
          url: require("@/assets/lift-success.png"),
          label: "正常",
          color: "rgba(47, 191, 76, 1)",
        },
        jianxiu: {
          iconUrl: require("@/assets/weixiu.png"),
          url: require("@/assets/lift-weixiu.png"),
          label: "检修",
          color: "#1962FF",
        },
        guzhang: {
          iconUrl: require("@/assets/guzhang.png"),
          url: require("@/assets/lift-guzhang.png"),
          label: "故障",
          color: "#FD3C3C",
        },
        lixian: {
          iconUrl: require("@/assets/xiaxian.png"),
          url: require("@/assets/lift-lixian.png"),
          label: "离线",
          color: "#909090",
        },
      },
      overflow: true,
    };
  },
  computed: {
    iconUrl() {
      return this.statusMap[this.status].iconUrl;
    },
    people() {
      const { properties = [] } = this.rowData;
      const overLoadSts = (properties || []).find(
        (item) => item.name === "overLoadSts"
      );
      const hasPerson = (properties || []).find(
        (item) => item.name === "hasPerson"
      );
      const fullLoadSts = (properties || []).find(
        (item) => item.name === "fullLoadSts"
      );
      if (overLoadSts && overLoadSts.value === "是") {
        return "超载";
      }
      if (fullLoadSts && fullLoadSts.value === "是") {
        return "满载";
      }

      if (hasPerson && hasPerson.value === "是") {
        return "有人";
      }
      return " 无人";
    },
    floor() {
      const { properties = [] } = this.rowData;
      const pos = (properties || []).find((item) => item.name === "pos");
      if (pos && pos.value) {
        return pos.value;
      }
      return "0";
    },
    dir() {
      const { properties = [] } = this.rowData;
      const direction = (properties || []).find(
        (item) => item.name === "direction"
      );
      if (direction) {
        return direction.value;
      }
      return "0";
    },
  },
  methods: {
    isOverflow(el) {
      if (!el) return false;
      const range = document.createRange();
      range.setStart(el, 0);
      range.setEnd(el, el.childNodes.length);
      // 所有子节点的宽度总和
      const rangeWidth = range.getBoundingClientRect().width;
      const getStyle = (el, key) => {
        if (!el || !key) return;
        return getComputedStyle(el)?.[key];
      };
      // 还需要加上容器元素的左右padding
      const cellChild = el;
      const padding =
        (parseInt(getStyle(cellChild, "paddingLeft"), 10) || 0) +
        (parseInt(getStyle(cellChild, "paddingRight"), 10) || 0);
      // 内容实际宽度
      const scrollWidth = rangeWidth + padding;
      // 内容当前宽度
      const clientWidth = el.getBoundingClientRect().width;
      console.log(rangeWidth, scrollWidth, clientWidth);

      if (clientWidth < scrollWidth) {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted() {
    const over1 = this.isOverflow(this.$refs.label);
    const over2 = this.isOverflow(this.$refs.id);
    this.overflow = over1 || over2;
    // return isOverflowing;
  },
  components: {
    LiftItem,
  },
};
</script>

<style lang="scss" scoped>
.box-wrapper {
  // top: 111px;
  // left: 834px;
  position: relative;
}
.map {
  // height: 290px;
  // width: 230px;
  // border-radius: 8px;
  // padding: 14px 16px;

  // background: rgba(71, 90, 135, 0.58);
  // border: 1px solid #80a2ff;
  // -webkit-backdrop-filter: blur(10px);
  // backdrop-filter: blur(10px);
}
.list {
  width: 178px;
}
.large {
  width: 298px;
  height: 420px;
}
.large_right {
  width: 100%;
  height: 100%;
}
.time {
  font-family: PingFangSC-Regular;
  font-size: 12px;
  color: #ffffff;
  letter-spacing: 0;
  font-weight: 400;
}
.list .time {
  color: #a0a9c0;
}

.large .time {
  color: #a0a9c0;
  font-size: 16px;
}
.lift-url {
  width: 178px;
  height: 234px;
  margin-top: 10px;
  position: relative;
  left: 2px;
}
.large .lift-url {
  width: 297px;
  height: 390px;
  margin-top: 20px;
  position: relative;
}
.bottom {
  // width: 180px;
  // background: rgba(58, 65, 81, 0.3);
  // border-radius: 12px;
  // -webkit-backdrop-filter: blur(15px);
  // backdrop-filter: blur(15px);
  // padding: 8px 13px 8px 18px;
  position: absolute;
  bottom: 0px;
}

.bottom-left {
  background: rgba(58, 65, 81, 0.3);
  border-radius: 12px;
  backdrop-filter: blur(15px);
  padding: 8px 12px 8px 18px;
  margin-right: 5px;
  height: 54px;
  width: 112px;
}
.large {
  .bottom-left {
    width: 187px;
    height: 90px;
    padding: 16px 50px 16px 33px;
    border-radius: 20px;
  }
  .bottom-right {
    width: 108px;
    height: 90px;
    padding: 13px 20px 12px 20px;
    border-radius: 20px;
  }
  .bignumber {
    padding: 13px 8px 12px 20px;
  }
}
.bottom-right {
  background: rgba(58, 65, 81, 0.3);
  border-radius: 12px;
  backdrop-filter: blur(15px);
  padding: 4px 3px 5px 14px;
  height: 54px;
  width: 65px;
}
.smallnumber {
  padding-left: 17px;
  padding-right: 8px;
}
.large .smallnumber {
  padding-left: 29px;
  padding-right: 12px;
}
.list {
  .bottom {
    bottom: -24px;
  }
}
.large {
  .bottom {
    height: 90px;
  }
  .label {
    font-size: 22px;
  }
  .id {
    font-size: 16px;
  }
}
.label {
  // width: 82px;
  font-family: PingFangSC-Medium;
  font-size: 13px;
  color: #ffffff;
  letter-spacing: 0;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.label-p {
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 22px;
  color: #ffffff;
  line-height: 30px;
  font-style: normal;
  margin-bottom: 6px;
}

.id {
  font-family: PingFangSC-Regular;
  font-size: 10px;
  color: #ffffff;
  letter-spacing: 0;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.id-p {
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 16px;
  color: #ffffff;
  line-height: 22px;
  font-style: normal;
}
.iconlabel {
  font-family: PingFangSC-Medium;
  font-size: 12px;
  color: #ffffff;
  letter-spacing: 0;
  font-weight: 500;
}
.list .iconlabel {
  color: #a0a9c0;
}

.large .iconlabel {
  color: #a0a9c0;
  font-size: 16px;
}
.iconUrl {
  width: 10px;
  height: 10px;
  margin-right: 6px;
}
.bg {
  position: absolute;
  width: 5px;
  height: 38px;
  border-radius: 2.4px;
  // background-color: #57D16F;
  bottom: 8px;
  left: 6px;
}
.large .iconUrl {
  width: 15px;
  height: 15px;
  margin-right: 7px;
}
.large .bg {
  width: 8px;
  height: 63px;
  z-index: 1;
  border-radius: 4px;
  left: 11px;
  bottom: 12px;
}
.triangle {
  // z-index: 10;
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 6px;
  filter: drop-shadow(0 2px 12px rgba(0, 0, 0, 0.03));
  // filter: drop-shadow(0 2px 12px );
  top: 30px;
  left: -6px;
  margin-bottom: 3px;
  border-right-color: #80a2ff;
  border-left-width: 0;
}
.triangle:after {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  content: "";
  border-width: 6px;
  bottom: -6px;
  left: 1px;
  border-right-color: transparent;
  border-left-width: 0;
}

.br-text-num {
  font-family: DIN, DIN;
  font-weight: bold;
  font-size: 23px;
  color: #ffffff;
  line-height: 28px;
  font-style: normal;
  margin-right: 3px;
}
.large {
  .br-text-num {
    font-size: 38px;
  }
  .br-text-x {
    font-size: 14px;
    min-width: 28px;
  }
}
.br-text-x {
  width: 17px;
  height: 12px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 8px;
  color: #ffffff;
  line-height: 12px;
  font-style: normal;
}
.right {
  position: relative;
  height: 273px;
}
.large_right {
  .right {
    height: 437px;
  }
}
</style>
