<template>
  <div>
    <Tool
      @changeMode="changeMode"
      :style="{ position: 'absolute', top: '80px', right: '664px' }"
    />
    <template v-if="size === 'small' && mode === 'map'">
      <el-popover
        v-for="(item, index) in list"
        :key="item.id"
        placement="right"
        width="431"
        trigger="click"
      >
        <div>
          <Lift
            :size="size"
            :mode="mode"
            :status="currentData.status"
            :time="currentData.time"
            :label="currentData.label"
            :id="currentData.id"
            :top="currentData.top"
            :left="currentData.left"
            :rowData="currentData"
          />
        </div>

        <div
          slot="reference"
          @click="clickLift(index + 1)"
          class="lift"
          :style="{
            top: item.s.top,
            left: item.s.left,
          }"
        ></div>
      </el-popover>
    </template>
    <template v-if="size === 'small' && mode === 'list'">
      <div
        :style="{
          position: 'absolute',
          top: '80px',
          left: '535px',
          right: '771px',
        }"
      >
        <el-row class="list1" :gutter="20">
          <el-col
            :span="8"
            v-for="item in list"
            :key="item.id"
            :style="{ marginBottom: '50px' }"
          >
            <el-popover placement="right" width="181" trigger="click">
              <LiftItem :mode="mode" :size="size" />
              <Lift
                slot="reference"
                :mode="mode"
                :size="size"
                :rowData="item"
                :status="item.status"
                :time="item.time"
                :label="item.label"
                :id="item.id"
              />
            </el-popover>
          </el-col>
        </el-row>
      </div>
    </template>
    <template v-if="size === 'large'">
      <el-popover
        v-for="(item, index) in list"
        :key="item.id"
        placement="right"
        width="650"
        trigger="click"
      >
        <Lift
          :size="size"
          :mode="mode"
          :status="currentData.status"
          :time="currentData.time"
          :label="currentData.label"
          :id="currentData.id"
          :showRight="true"
          :rowData="currentData"
        />
        <div
          slot="reference"
          @click="clickLift(index + 1)"
          class="lift"
          :style="{
            top: item.l.top,
            left: item.l.left,
          }"
        ></div>
      </el-popover>
    </template>
    <template v-if="size === 'large'">
      <div
        :style="{
          position: 'absolute',
          top: '56px',
          left: '3547px',
          right: '105px',
        }"
      >
        <el-row :gutter="20">
          <el-col
            :span="6"
            v-for="item in list"
            :key="item.id"
            :style="{ marginBottom: '84px' }"
          >
            <el-popover placement="right" width="250px" trigger="click">
              <LiftItem m="list" :mode="mode" :size="size" />
              <Lift
                slot="reference"
                :mode="mode"
                :size="size"
                :status="item.status"
                :time="item.time"
                :rowData="item"
                :label="item.label"
                :id="item.id"
              />
            </el-popover>
          </el-col>
        </el-row>
      </div>
    </template>
  </div>
</template>

<script>
const MOCK_LIST = [
  {
    // id: "20H001931",
    innerId: 1,
    // status: "success",
    // time: "2024/04/23",
    // label: "东一楼A梯",
    s: {
      top: "215px",
      left: "799px",
    },
    l: {
      top: "178px",
      left: "2382px",
    },
  },
  {
    // id: "20H001932",
    innerId: 2,
    // status: "lixian",
    // time: "2024/04/23",
    // label: "东一楼B梯",
    top: "111px",
    left: "850px",
    s: {
      top: "221px",
      left: "820px",
    },
    l: {
      top: "184px",
      left: "2405px",
    },
  },
  {
    // id: "20H001933",
    innerId: 3,
    // status: "guzhang",
    // time: "2024/04/23",
    // label: "东一楼A梯",
    top: "125px",
    left: "812px",
    s: {
      top: "230px",
      left: "784px",
    },
    l: {
      top: "194px",
      left: "2366px",
    },
  },
  {
    // id: "20H001934",
    innerId: 4,
    // status: "jianxiu",
    // time: "2024/04/23",
    // label: "东一楼A梯",
    top: "126px",
    left: "830px",
    s: {
      top: "236px",
      left: "805px",
    },
    l: {
      top: "200px",
      left: "2389px",
    },
  },
  {
    // id: "20H001935",
    innerId: 5,
    // status: "success",
    // time: "2024/04/23",
    // label: "东一楼A梯",
    top: "96px",
    left: "1011px",
    s: {
      top: "203px",
      left: "981px",
    },
    l: {
      top: "134px",
      left: "2751px",
    },
  },
  {
    // id: "20H001936",
    innerId: 6,
    // status: "success",
    // time: "2024/04/23",
    // label: "东一楼A梯",
    top: "116px",
    left: "994px",
    s: {
      top: "220px",
      left: "965px",
    },
    l: {
      top: "149px",
      left: "2737px",
    },
  },
  {
    // id: "20H001937",
    innerId: 7,
    // status: "success",
    // time: "2024/04/23",
    // label: "东一楼A梯",
    top: "131px",
    left: "977px",
    s: {
      top: "237px",
      left: "948px",
    },
    l: {
      top: "164px",
      left: "2722px",
    },
  },
];
const fn = (item) => {
  const { onlineStatusName, serverStatusName } = item;
  if (onlineStatusName === "离线") {
    return "lixian";
  }
  if (serverStatusName === "故障") {
    return "guzhang";
  }
  if (serverStatusName === "检修") {
    return "jianxiu";
  }
  return "success";
};
import Lift from "../../common/lift";
import Tool from "../../common/tool";
import LiftItem from "../../common/liftitem";
export default {
  name: "liftmap",
  props: {
    size: {
      type: String,
    },
  },
  data() {
    return {
      currentLift: 1,
      mode: "map",
      listVisible: false,
    };
  },
  computed: {
    list() {
      const data = this.$store.state.liftList;

      const d = data.map((item, index) => {
        return {
          ...item,
          ...MOCK_LIST[index],
          id: item.liftCode,
          label: item.liftName,
          status: fn(item),
          time: "",
        };
      });
      return d;
    },
    isShow() {
      return Boolean(this.currentLift);
    },
    currentData() {
      return this.list[this.currentLift - 1];
    },
  },
  components: {
    Lift,
    Tool,
    LiftItem,
  },
  mounted() {},
  beforeDestroy() {},
  methods: {
    clickLift(index) {
      this.currentLift = index;
      this.$store.dispatch("fetchLiftDetail", this.list[index - 1].id);
      // this.$store.dispatch("fetchLiftStatusStatisTics", {liftCode:this.list[index].id});
    },
    changeMode(mode) {
      this.mode = mode;
      this.$emit("changeMode", mode);
    },
  },
};
</script>

<style lang="scss" scoped>
.lift {
  width: 26px;
  height: 15px;
  transform: skew(-37deg) rotate(12deg);
  position: absolute;
  border: 1px solid #f4f6ff;
  cursor: pointer;
  background-image: linear-gradient(
    180deg,
    #5f9eff 0%,
    rgba(55, 73, 255, 0.29) 100%
  );
}
.lift-1 {
  top: 215px;
  left: 799px;
}
.lift-2 {
  top: 221px;
  left: 820px;
}
.lift-3 {
  top: 230px;
  left: 784px;
}
.lift-4 {
  top: 236px;
  left: 805px;
}
.lift-5 {
  top: 203px;
  left: 981px;
}

.lift-6 {
  top: 220px;
  left: 965px;
}

.lift-7 {
  top: 237px;
  left: 948px;
}

.list1 {
  height: 700px;
  overflow-y: scroll;
  backdrop-filter: blur(10px);
}
/* 设置滚动条的滑块颜色 */
::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.1);
      // background-color: transparent

}
::-webkit-scrollbar-thumb:hover{
    background-color: rgba(255, 255, 255, 0.4);

}

/* 设置滚动条的轨道颜色 */
::-webkit-scrollbar-track {
  background-color: transparent;
}
</style>
