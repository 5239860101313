<template>
  <div
    class="right-inner"
    :class="{
      small_list: size === 'small' && mode === 'list',
      small_map:size === 'small' && mode === 'map',
      large_map: size === 'large' && m !== 'list',
      large_list: size === 'large' && m === 'list',
    }"
  >
    <div class="d-flex jc-between ai-center">
      <div></div>
      <FilterLabel
        v-model="activeIndex"
        class="filter-2"
        :style="{ width: '100px' }"
        :data="[' 周', '月', '年']"
      />
    </div>
    <div
      class="right-t d-flex jc-between ai-center"
      :class="{
        map_t: size === 'small' && mode === 'map',
        small_list_t: size === 'small' && mode === 'list',
        large_map_t: size === 'large' && m !== 'list',
        large_list_t: size === 'large' && m === 'list',
      }"
    >
      <div>耗电量：</div>
      <div>1600kW·h</div>
    </div>
    <div class="right-t d-flex jc-between ai-center">
      <div>运行时长：</div>
      <div>15h</div>
    </div>
    <div class="right-t d-flex jc-between ai-center">
      <div>故障率：</div>
      <div>2%</div>
    </div>
    <div class="right-t d-flex jc-between ai-center">
      <div>电梯效率：</div>
      <div>92%</div>
    </div>
  </div>
</template>

<script>
import FilterLabel from "../filter";
export default {
  name: "liftitem",
  data() {
    return {
      activeIndex: 0,
    };
  },
  props: {
    size: {
      type: String,
    },
    mode: {
      type: String,
    },
    m: {
      type: String,
    },
  },
  components: {
    FilterLabel,
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.right-inner {
  padding: 11px 8px 11px 28px;
  border-left: 1px dashed #ffffff;
  height: 100%;
  margin-left: 11px;
}
.right-t {
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 12px;
  color: #fbfbfb;
  line-height: 17px;
  margin-top: 10px;
  font-style: normal;
}
.large_map .right-t {
  font-size: 18px;
  margin-top: 16px;
  padding: 11px 16px 11px 28px;
}
.right-t:first-child {
  margin-top: 56px;
}
.small_list {
  padding-top: 0px;
  border-left-width: 0px;
  padding-left: 0px;
  .right-t {
    font-size: 12px;
    margin-top: 10px;
  }
}
.large_map {
  // border-left-width: 0px;
  // padding-left: 0px;
  width: 100%;
}
.large_list {
  width:181px;
  border-left-width: 0px;
  padding-left: 0px;
}
.small_map .map_t {
  margin-top: 56px;
}
.large_map .large_map_t {
  margin-top: 70px;
}
.large_list .large_list_t {
  margin-top: 20px;
}
.small_list .small_list_t{
  margin-top: 25px;
}
</style>
