<template>
  <Box
    title="电梯能耗趋势图"
    :width="size === 'small' ? '472px' : '501px'"
    height="220px"
  >
    <template slot="title-right">
      <FilterLabel v-model="activeIndex" :data="['当月', '当年']" />
    </template>
    <div class="d-flex wrapper flex-column">
      <Pointer
        :data="[
          { label: '运行时长（h）', color: ['rgba(25,98,255,0.60)', '#1962FF'] },
        ]"
      />
      <Echart :options="options" height="130px" width="100%"></Echart>
    </div>
  </Box>
</template>

<script>
import Box from "../../common/box";
import Echart from "@/common/echart";

import FilterLabel from "../../common/filter";
import Pointer from "../../common/pointer";
var gradientColor = [
  { offset: 1, color: "rgba(25,98,255,0.60)  " },
  { offset: 0, color: "#1962FF" },
];
export default {
  name: "energyconsumptiontrend",
  props: {
    size: {
      type: String,
    },
  },
  data() {
    return {
      options: {
        // grid: { left: 30, right: 0, top: 10, bottom: 20 },

        // tooltip: {
        //   trigger: "axis",

        //   formatter: (arr) => {
        //     const { axisValue, data } = arr[0];

        //     return `<div class="tooltip-w d-flex flex-column">
        //         <div class="tooltip-title">${axisValue}</div>
        //         <div class="tooltip-y">能耗：${data[1]}h</div>
        //         </div>`;
        //   },
        // },
        xAxis: {
          type: "category",
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            barWidth: 7,
            data: [
              ["10/01", 200],
              ["10/02", 560],
              ["10-03", 750],
              ["10-04", 580],
              ["10-05", 250],
              ["10-06", 300],
              ["10-07", 450],
              ["10/09", 100],
              ["10/08", 300],
              ["10/10", 100],
              ["10/11", 200],
              ["10/12", 560],
              ["10/13", 750],
              ["10/14", 580],
              ["10/15", 250],
              ["10/16", 300],
              ["10/17", 450],
              ["10/18", 300],
              ["10/19", 100],
              ["10/20", 100],
              ["10/21", 200],
              ["10/22", 560],
              ["10/23", 750],
              ["10/24", 580],
              ["10/25", 250],
              ["10/26", 300],
              ["10/27", 450],
              ["10/28", 300],
              ["10/29", 100],
              ["10/30", 100],
              ["10/31", 100],
            ],
            type: "bar",
            itemStyle: {
              color: new this.$echarts.graphic.LinearGradient(
                0,
                0,
                0,
                1,
                gradientColor
              ),
              barBorderRadius: [2, 2, 0, 0],
             
            },
          },
        ],
      },
      activeIndex: 0,
      isActive: true,
    };
  },
  computed: {},
  components: {
    Box,
    Echart,
    FilterLabel,
    Pointer,
  },
  mounted() {},
  beforeDestroy() {},
  methods: {},
};
</script>

<style lang="scss" scoped></style>
