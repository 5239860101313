<template>
  <div class="box-wrapper" :style="{ height: height, width: width }" >
    <div class="d-flex flex-column">
      <div class="d-flex jc-between al-center box-title-wrapper">
        <div class="box-title">{{ title }}</div>
        <slot name="title-right"></slot>
      </div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "box",
  props: {
    title: {
      type: String,
    },
    width: {
      type: String,
      default: "100%",
    },
    height: {
      type: String,
      default: "2.5rem",
    },
  },
};
</script>

<style lang="scss" scoped>
.box-wrapper {
  padding: 20px 22px;
  border-radius: 20px;
  background-color: rgba(58, 65, 81, 0.3);
  -webkit-backdrop-filter: blur(10px);
	backdrop-filter: blur(10px);
  overflow: hidden;
}
.box-title {
  font-family: PingFangSC-Medium;
  font-size: 16px;
  line-height: 20px;
  height: 20px;
  color: #ffffff;
  letter-spacing: 0;
  font-weight: 500;
}
</style>
