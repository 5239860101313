<template>
    <div :style="{ marginTop: '20px' }">
      <div v-for="item in innerData" :key="item.id">
        <div class="d-flex jc-between f">
          <div class="title-item">{{ item.label }}</div>
          <div>{{ item.showValue ? item.showValue : item.value }}</div>
        </div>
        <div class="bar" :style="{ width: item.percent }"></div>
      </div>
    </div>

</template>

<script>

export default {
  name: "Capsule",
  props: {
    data: {
      type: Array,
      default: () => [],
      validator: function (value) {
        return value.every(
          (item) =>
            typeof item.label === "string" && typeof item.value === "number"
        );
      },
    },
  
  },
  computed: {
    innerData() {
      const sum = this.data.reduce((prev, curr) => prev + curr.value, 0);
      return this.data.map((item) => ({
        ...item,
        showValue: item.formatter ? item.formatter(item) : "",
        percent: (item.value / sum).toFixed(2) * 100 + "%",
      }));
    },
  },
  components: {

  },
};
</script>

<style lang="scss" scoped>
.f {
  font-family: PingFangSC-Regular;
  font-size: 12px;
  color: #ffffff;
  letter-spacing: 0;
  font-weight: 400;
}
.bar {
  background-image: linear-gradient(270deg, #6ba3ff 0%, #1962ff 100%);
  border-radius: 4.21px;
  margin-top: 10px;
  margin-bottom: 20px;
  height: 6px;
}
</style>
