<template>
  <Box
    title=" 电梯综合效率排行"
    :width="size === 'small' ? '490px' : '513px'"
    height="220px"
  >
    <template slot="title-right">
      <!-- <FilterLabel :data="['当日', '当月']" /> -->
      <Select v-model="value" />
        </template>
    <div class="d-flex wrapper flex-column">
      <Pointer
        :data="[
          {
            label: '百分比（%）',
            color: [' rgba(25,255,245,0.60) ', '#19FFD6 '],
          },
        ]"
      />
      <Echart :options="options" height="130px" width="100%"></Echart>
    </div>
  </Box>
</template>

<script>
import Box from "../../common/box";
import Echart from "@/common/echart";
// import FilterLabel from "../../common/filter";
import Select from '../../common/select'
import Pointer from "../../common/pointer";
var gradientColor = [
  { offset: 1, color: "rgba(25,255,245,0.60)" },
  { offset: 0, color: "#19FFD6 " },
];
export default {
  name: "efficiencyranking",
  props: {
    size: {
      type: String,
    },
  },
  data() {
    return {
      options: {
        tooltip: {
          trigger: "axis",

          formatter: (arr) => {
            const { axisValue, data } = arr[0];
            return `<div class="tooltip-w d-flex flex-column">
                <div class="tooltip-title">2024/08/01</div>
                <div class="tooltip-x">${axisValue}</div>
                <div class="tooltip-y"> 综合效率：${data}%</div>
                </div>`;
          },
        },
        xAxis: {
          type: "category",
          data: [
            "东一楼 A 梯子",
            "东一楼 A 梯子",
            "东一楼 A 梯子",
            "东一楼 A 梯子",
            "东一楼 A 梯子",
            "东一楼 A 梯子",
            "东一楼 A 梯子",
            "东一楼 A 梯子",
          ],
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            barWidth: 20,
            data: [8, 7, 6, 5, 3, 6, 8, 8],
            type: "bar",
            itemStyle: {
              color: new this.$echarts.graphic.LinearGradient(
                0,
                0,
                0,
                1,
                gradientColor
              ),
              // borderColor: "rgba(255,255,255,0.3)",
              // borderWidth: 1,
              barBorderRadius: [3, 3, 0, 0],
              // shadowBlur: 2, // 模糊半径，较小的值模拟 1px 的模糊
              // shadowColor: "rgba(255, 255, 255, 0.3)", // 阴影颜色
              // shadowOffsetX: 0, // X 轴偏移量为 0 实现内阴影
              // shadowOffsetY: 0, // Y 轴偏移量为 0 实现内阴影
            },
          },
        ],
      },
      value: ["电梯", "全天", "月"],
    };
  },
  computed: {},
  components: {
    Box,
    Echart,
    // FilterLabel,
    Select,
    Pointer,
  },
  mounted() {},
  beforeDestroy() {},
  methods: {},
};
</script>

<style lang="scss" scoped></style>
